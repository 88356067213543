<!-- src/components/Footer.vue -->
<template>
    <footer class="bg-secondary text-white p-4 text-center mt-10">
      <p class="text-sm">&copy; 2024 Arnold/Boam Productions. All rights reserved.</p>
    </footer>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  </style>
  