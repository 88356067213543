// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import './assets/main.css';
import { createHead } from '@vueuse/head';
import { initGTM } from './gtm';

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(head);

// Initialize GTM
initGTM();

app.mount('#app');
