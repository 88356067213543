<template>
  <div id="app">
    <header class="bg-secondary text-white p-4">
      <nav class="flex flex-wrap justify-between">
        <div class="w-full md:w-auto text-xl font-bold mb-4 md:mb-0">
          <router-link to="/">The Angry Youth</router-link>
        </div>
        <div class="flex flex-wrap space-x-4 md:pt-1">
          <router-link to="/" class="hover:text-highlight">Home</router-link>
          <router-link to="/gallery" class="hover:text-highlight">Gallery</router-link>
          <router-link to="/script" class="hover:text-highlight">Script</router-link>
          <router-link to="/credits" class="hover:text-highlight">Credits</router-link>
          <a href="/v1/index.html" target="_blank" rel="noopener noreferrer" class="hover:text-highlight">Old Site</a>
        </div>
      </nav>
    </header>
    <main>
      <router-view/>
    </main>
  </div>
</template>

<script setup>
</script>

<style scoped>
nav a {
  text-decoration: none;
}
nav a:hover {
  text-decoration: underline;
}
</style>
