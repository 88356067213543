<template>
  <div class="p-6 bg-primary text-white min-h-screen">
    <h1 class="text-3xl font-bold mb-4">Credits</h1>
    <p class="mb-6">Visit the Credits section to see the actors, contributors, and music artists who brought 
      "The Angry Youth" to life, honoring their invaluable contributions to this powerful project.</p>

    <section class="space-y-8">
      <div>
        <h3 class="text-xl font-bold">Creators</h3>
        <ul class="list-disc list-inside">
          <li>Matt Boam</li>
          <li>Greg Arnold</li>
          <li>Sam McColl</li>
        </ul>
      </div>

      <div>
        <h3 class="text-xl font-bold">Actors</h3>
        <ul class="list-disc list-inside">
          <li>Greg Arnold as Locin Shaw, The Angry Youth</li>
          <li>Matt Boam as Billy</li>
          <li>Sam McColl as Dealer Rick/Dealer Jimmy</li>
          <li>Brian Knighton as The Paperboy</li>
          <li>Travis Heath as ??? (Angry Youth II: The Hovering)</li>
          <li>Brian McColl as Dealer Rick's father (Angry Youth II: The Hovering)</li>
        </ul>
      </div>

      <div>
        <h3 class="text-xl font-bold">Music</h3>
        <ul class="list-disc list-inside">
          <li>Arabian Prince</li>
          <li>Terminator X</li>
          <li>Brand X</li>
          <li>Beastie Boys</li>
          <li>Eazy E</li>
          <li>Ice T</li>
          <li>NWA</li>
          <li>Metallica</li>
          <li>Guns and Roses</li>
          <li>Angelo Gabooche</li>
          <li>Depeche Mode</li>
          <li>The BMF</li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useHead } from '@vueuse/head';

useHead({
  title: 'Credits - The Angry Youth',
  meta: [
    { name: 'description', content: 'Visit the Credits section to see the actors, contributors, and music artists who brought "The Angry Youth" to life, honoring their invaluable contributions to this powerful project.' },
    { name: 'keywords', content: 'credits, actors, music artists, contributors' },
  ],
});
</script>

<style scoped>
</style>
