<template>
  <div class="p-6 bg-primary text-white min-h-screen">
    <h1 class="text-3xl font-bold mb-4">Gallery</h1>
    <p class="mb-6">Explore the Gallery for images and scenes from "The Angry Youth," capturing the essence 
      and intensity of the movie's powerful narrative.</p>

    <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
      <div v-for="(image, index) in images" :key="index" class="cursor-pointer" @click="openModal(image)">
        <img :src="image.src" :alt="image.caption" class="w-full h-32 md:h-48 object-cover rounded-lg shadow-lg">
        <p class="mt-2 text-center">{{ image.caption }}</p>
      </div>
    </div>

    <div class="mt-8 mb-6">
      <h3 class="text-xl font-bold">Previously Unreleased</h3>
    </div>

    <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
      <div v-for="(image, index) in unreleased" :key="index" class="cursor-pointer" @click="openModal(image)">
        <img :src="image.src" :alt="image.caption" class="w-full h-32 md:h-48 object-cover rounded-lg shadow-lg">
        <p class="mt-2 text-center">{{ image.caption }}</p>
      </div>
    </div>

    <TransitionRoot as="template" appear :show="isOpen" @close="closeModal">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeModal">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
              <div>
                <img :src="selectedImage.src" :alt="selectedImage.caption" class="w-full h-auto rounded-lg">
                <div class="mt-4">
                  <p class="text-lg leading-6 font-medium text-gray-900 text-center">{{ selectedImage.caption }}</p>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useHead } from '@vueuse/head'

useHead({
  title: 'Gallery - The Angry Youth',
  meta: [
    { name: 'description', content: 'Explore the Gallery for images and scenes from "The Angry Youth," capturing the essence and intensity of the movie\'s powerful narrative.'},
    { name: 'keywords', content: 'gallery, images, photos' },
  ],
})

const isOpen = ref(false)
const selectedImage = ref({ src: '', caption: '' })

const images = [
  { src: new URL('@/assets/movie/drug1.jpg', import.meta.url).href, caption: 'Dealer Rick makes a deal.' },
  { src: new URL('@/assets/movie/fight.jpg', import.meta.url).href, caption: 'Billy and Locin mix it up.' },
  { src: new URL('@/assets/movie/group.jpg', import.meta.url).href, caption: 'Splitting up the cash.' },
  { src: new URL('@/assets/movie/paperboy.jpg', import.meta.url).href, caption: 'The paperboy gets unlucky.' },
  { src: new URL('@/assets/movie/runover.jpg', import.meta.url).href, caption: 'Payback.' },
  { src: new URL('@/assets/movie/traitor.jpg', import.meta.url).href, caption: 'The betrayal of Dealer Rick.' },
  { src: new URL('@/assets/movie/closet.jpg', import.meta.url).href, caption: '"I got lost!"' },
];

const unreleased = [
  { src: new URL('@/assets/movie/ang-musc.jpg', import.meta.url).href, caption: 'Wakin\' up, to the sound...' },
  { src: new URL('@/assets/movie/ang-fenc.jpg', import.meta.url).href, caption: 'Hard luck life.' },
  { src: new URL('@/assets/movie/ang-door.jpg', import.meta.url).href, caption: 'Billy has a surprise.' },
  { src: new URL('@/assets/movie/ang-kill.jpg', import.meta.url).href, caption: 'Murder is easy.' },
  { src: new URL('@/assets/movie/ang-burn.jpg', import.meta.url).href, caption: 'Getting even.' },
  { src: new URL('@/assets/movie/ang-shed.jpg', import.meta.url).href, caption: 'Waiting for the dopeman.' },
];

const openModal = (image) => {
  selectedImage.value = image
  isOpen.value = true
}

const closeModal = () => {
  isOpen.value = false
}
</script>

<style scoped>
</style>
