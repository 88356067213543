<template>
  <div class="min-h-screen bg-primary text-white flex flex-col items-center justify-center">

    <header class="text-center flex items-center justify-center space-x-4 px-4 md:px-0">
      <img src="@/assets/skullr.gif" alt="Skull Image" class="skull-image w-12 h-12 md:w-16 md:h-16" />
      <h1 class="text-3xl md:text-5xl font-bold custom-title">The Angry Youth</h1>
      <img src="@/assets/skullr.gif" alt="Skull Image" class="skull-image skull-image-right w-12 h-12 md:w-16 md:h-16" />
    </header>

    <section class="mt-10 mb-6 px-4 text-center max-w-2xl">
      <p class="mb-4">
        "The Angry Youth" is a project initiated in the early 1990s. Traditionally classified as a movie, 
        its creators—Matt Boam, Greg Arnold, and later, Sam McColl—envision "The Angry Youth" as a concept 
        rather than merely a film.
      </p>
      <p class="mb-4">
        The idea of a troubled youth, with a worldview shaped by corruption and violence, is far from novel. 
        The 1994 film "Natural Born Killers" is just one example highlighting the societal decay and its 
        impact on the nation's young minds.
      </p>
      <p>
        This site serves as a tribute to the visionary work of its creators, honoring their effort to 
        spotlight this crucial concept.
      </p>
    </section>

    <main class="mt-8">
      <div class="px-4 md:px-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div class="p-6 bg-secondary rounded-lg shadow-lg hover:bg-accent cursor-pointer" @click="openYouTube">
          <h2 class="text-2xl font-semibold mb-2">The Movie</h2>
          <p>Watch "The Angry Youth" movie, a powerful narrative that brings the concept of troubled youth to life through a gripping storyline.</p>
        </div>
        <router-link to="/gallery" class="p-6 bg-secondary rounded-lg shadow-lg hover:bg-accent">
          <h2 class="text-2xl font-semibold mb-2">Gallery</h2>
          <p>Explore the Gallery for images and scenes from "The Angry Youth," capturing the essence 
            and intensity of the movie's powerful narrative.</p>
        </router-link>
        <router-link to="/script" class="p-6 bg-secondary rounded-lg shadow-lg hover:bg-accent">
          <h2 class="text-2xl font-semibold mb-2">Script</h2>
          <p>Discover the compelling script of "The Angry Youth," a narrative that delves into the 
            psyche of a troubled youth, bringing this powerful concept to life.</p>
        </router-link>
        <router-link to="/credits" class="p-6 bg-secondary rounded-lg shadow-lg hover:bg-accent">
          <h2 class="text-2xl font-semibold mb-2">Credits</h2>
          <p>Visit the Credits section to see the actors, contributors, and music artists who brought 
            "The Angry Youth" to life, honoring their invaluable contributions to this powerful project.</p>
        </router-link>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script setup>
import { useHead } from '@vueuse/head';
import Footer from '@/components/Footer.vue';

useHead({
  title: 'The Angry Youth',
  meta: [
    { name: 'description', content: 'A place for the youth to express their frustrations and find solace.' },
    { name: 'keywords', content: 'angry, youth, gone wild, solace, gallery, script, credits' },
  ],
});

const openYouTube = () => {
  window.open('https://www.youtube.com/watch?v=I4FToHyzLM0', '_blank');
};
</script>

<style scoped>
.custom-title {
  color: white;
  text-shadow: 2px 2px 8px black;
}
.skull-image {
  width: 100px;
  height: 100px;
}
.skull-image-right {
  transform: scaleX(-1);
}
</style>
