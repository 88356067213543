<template>
  <div class="p-6 bg-primary text-white min-h-screen">
    <h1 class="text-3xl font-bold mb-4">Script</h1>
    <p class="mb-6">Discover the compelling script of "The Angry Youth," a narrative that delves into the 
      psyche of a troubled youth, bringing this powerful concept to life.</p>

    <section class="space-y-8">
      <div>
        <h3 class="text-xl font-bold">Written by</h3>
        <ul class="list-disc list-inside">
          <li>Matt Boam</li>
          <li>Greg Arnold</li>
          <li>Sam McColl</li>
        </ul>
      </div>

      <hr class="border-gray-500">

      <div>
        <h3 class="text-xl font-bold">Scene 1</h3>
        <p>(Locin sits in living room listening to Arabian Prince)</p>
        <p>(Locin gets up and dials on the phone)</p>
        <div class="pl-4">
          <p><strong>Locin:</strong> Billy? Get over here, man. I got something to show you, man.</p>
          <p><strong>Locin:</strong> Yeah, yeah. Yeah, good. Good.</p>
        </div>
        <p>(Time lapse)</p>
        <p>(Doorbell rings)</p>
        <p>(Locin answers and Billy enters)</p>
        <div class="pl-4">
          <p><strong>Locin:</strong> Yo man, come on in.</p>
          <p><strong>Locin:</strong> Dude, I'll be right back. I'm gonna go get it.</p>
        </div>
        <p>(Locin enters kitchen and reaches for the gat)</p>
        <div class="pl-4">
          <p><strong>Billy:</strong> Whatcha got there?</p>
        </div>
        <p>(Billy turns to run out door)</p>
        <div class="pl-4">
          <p><strong>Billy:</strong> Hey man! Hey man, NO WAY!</p>
        </div>
        <p>(Billy runs and Locin chases with the gun)</p>
        <p>(Locin shoots Billy in the back and presumes he's dead)</p>
        <p>(Billy's hand grasps the grass and fights to stay alive)</p>
      </div>

      <hr class="border-gray-500">

      <div>
        <h3 class="text-xl font-bold">Scene 2</h3>
        <p>(The next day)</p>
        <p>(Locin exits house and gets into red Ford Ranger)</p>
        <p>(Locin gets in truck and drives off)</p>
        <p>(TIME LAPSE)</p>
        <p>(Locin arrives at desolate corner with Dealer Rick waiting)</p>
        <p>(Locin approaches Dealer Rick)</p>
        <div class="pl-4">
          <p><strong>Locin:</strong> You got it?</p>
          <p><strong>Dealer Rick:</strong> I got it.</p>
        </div>
        <p>(Dealer Rick pulls out a large bag of cocaine)</p>
        <div class="pl-4">
          <p><strong>Locin:</strong> Is it good?</p>
          <p><strong>Dealer Rick:</strong> 'Course it's good!</p>
        </div>
        <p>(Locin tastes the cocaine with a knife)</p>
        <p>(Locin steps towards Dealer Rick and stabs him in the stomach)</p>
        <div class="pl-4">
          <p><strong>Dealer Rick:</strong> Auuhh!!</p>
        </div>
        <p>(Locin runs to his truck and tears off)</p>
      </div>

      <hr class="border-gray-500">

      <div>
        <h3 class="text-xl font-bold">Scene 3</h3>
        <p>(Dealer Rick sits by the phone counting out $100 bills)</p>
        <p>(Phone rings)</p>
        <div class="pl-4">
          <p><strong>Dealer Rick:</strong> That'll do for a day.</p>
        </div>
        <p>(Dealer Rick answers the phone)</p>
        <div class="pl-4">
          <p><strong>Dealer Rick:</strong> Yeah.</p>
          <p><strong>Billy:</strong> Yo, this is Billy.</p>
          <p><strong>Dealer Rick:</strong> Hey, what's going on?</p>
          <p><strong>Billy:</strong> Yo, I got a plan. I can't talk on the phone though. Meet me at the corner of 22nd and Johnson.</p>
          <p><strong>Dealer Rick:</strong> OK</p>
        </div>
        <p>(Dealer Rick hangs up phone)</p>
      </div>

      <hr class="border-gray-500">

      <div>
        <h3 class="text-xl font-bold">Scene 4</h3>
        <p>(Billy waits outside a small barn holding gun)</p>
        <p>(Dealer Rick enters)</p>
        <div class="pl-4">
          <p><strong>Dealer Rick:</strong> Whoa, what's with the gun?</p>
          <p><strong>Billy:</strong> Hey man, I've changed.</p>
          <p><strong>Dealer Rick:</strong> Hey, I can deal with that.</p>
          <p><strong>Billy:</strong> Yo, I got a plan against Locin.</p>
          <p><strong>Dealer Rick:</strong> The Shawster?</p>
          <p><strong>Billy:</strong> WHAT DO YOU THINK??!!!</p>
        </div>
      </div>

      <hr class="border-gray-500">

      <div>
        <h3 class="text-xl font-bold">Scene 5</h3>
        <p>(Phone rings at Locin's house)</p>
        <p>(Locin enters and answers phone)</p>
        <div class="pl-4">
          <p><strong>Locin:</strong> Yo, this is the Locster.</p>
          <p><strong>Locin:</strong> Hey man, I thought I killed you?</p>
          <p><strong>Locin:</strong> Huh, huh, you want to join forces with me?</p>
          <p><strong>Locin:</strong> Are you serious?</p>
          <p><strong>Locin:</strong> Him too?</p>
          <p><strong>Locin:</strong> Ok, yeah, good.</p>
        </div>
      </div>

      <hr class="border-gray-500">

      <div>
        <h3 class="text-xl font-bold">Scene 6</h3>
        <p>(Terminator X plays in Locin's living room)</p>
        <p>(Doorbell rings)</p>
        <p>(Locin enters and answers door)</p>
        <p>(Dealer Rick and Billy enter)</p>
        <div class="pl-4">
          <p><strong>Locin:</strong> Come on in. You want something to drink? Beer? Vodka?</p>
          <p><strong>Dealer Rick:</strong> No thanks, we just got back from the joint.</p>
        </div>
        <p>(All three enter the living room)</p>
        <div class="pl-4">
          <p><strong>Locin:</strong> So boys, why the change of heart?</p>
          <p><strong>Dealer Rick:</strong> With the three of us together, we could do some serious crime; some serious business.</p>
          <p><strong>Billy (to Locin):</strong> You think you're so cool don't you?</p>
          <p><strong>Locin:</strong> Pack it, buddy!</p>
        </div>
        <p>(Locin starts towards Billy)</p>
        <div class="pl-4">
          <p><strong>Dealer Rick:</strong> HEY, HEY!</p>
          <p><strong>Billy:</strong> I don't need to take his crap!</p>
          <p><strong>Dealer Rick:</strong> Hey, I've got an idea. Lets go hit up the Circle K.</p>
          <p><strong>Billy:</strong> Is that what you want Dealer Rick?</p>
          <p><strong>Dealer Rick (to camera):</strong> No, that's only the beginning!</p>
        </div>
      </div>

      <hr class="border-gray-500">

      <div>
        <h3 class="text-xl font-bold">Stay tuned for more script development...</h3>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useHead } from '@vueuse/head';

useHead({
  title: 'Script - The Angry Youth',
  meta: [
    { name: 'description', content: 'Discover the compelling script of "The Angry Youth," a narrative that delves into the psyche of a troubled youth, bringing this powerful concept to life.' },
    { name: 'keywords', content: 'movie, script, dialog' },
  ],
});
</script>

<style scoped>
</style>
